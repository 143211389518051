import { Client } from 'graphql-ws';
import { useEffect } from 'react';
import { useInterval } from 'usehooks-ts';

import { DefaultThemeProvider } from '~served/ui-comps';
import { bigMath } from '~served/utils';

import { useInitApp } from '~/hooks/useInitApp';
import { getHubGqlWsClient } from '~/libs/gql';
import { subscribeToSystemHealth } from '~/queries/useSubscribeToSystemHealth';
import { subscribeToVenueLocations } from '~/queries/useSubscribeToVenueLocations';
import { subscribeToVenueNotifications } from '~/queries/useSubscribeToVenueNotifications';
import { subscribeToVenueOrders } from '~/queries/useSubscribeToVenueOrders';
import { subscribeToVenueRefetchOperationalLocationsCommand } from '~/queries/useSubscribeToVenueRefetchOperationalLocationsCommand';
import { subscribeToVenueRefetchOperationalOrdersCommand } from '~/queries/useSubscribeToVenueRefetchOperationalOrdersCommand';
import { useVenueId } from '~/store';
import { useGetOperationalOrders } from '~/queries/useGetOperationalOrders';
import { useGetOperationalLocations } from '~/queries/useGetOperationalLocations';
import { useGetOperationalGeolocations } from '~/queries/useGetOperationalGeolocations';
import { useGetOperationalNotifications } from '~/queries/useGetOperationalNotifications';
import { useGetCurrentVenue } from '~/queries/useGetCurrentVenue';

const AppProvider = ({ children }) => {
	useInitApp();
	useWsSubscriptions();
	useRefetchOperationalData();

	return <DefaultThemeProvider>{children}</DefaultThemeProvider>;
};

export { AppProvider };

let client: Client | undefined;

const useWsSubscriptions = () => {
	const venueId = useVenueId();
	const { refetch: refetchOrders } = useGetOperationalOrders(false);
	const { refetch: refetchLocations } = useGetOperationalLocations(false);
	const { refetch: refetchGeolocations } = useGetOperationalGeolocations(false);
	const { refetch: refetchNotifications } = useGetOperationalNotifications(false);

	useEffect(() => {
		async function start() {
			if (client) {
				client.dispose();
				client.terminate();
			}

			client = getHubGqlWsClient();

			/**
			 * put all subscriptions here
			 * so that when params change, the client is re-initialized with all the subscriptions
			 */

			subscribeToSystemHealth(client);
			if (venueId) {
				refetchOrders();
				refetchLocations();
				refetchGeolocations();
				refetchNotifications();

				subscribeToVenueLocations(client);
				subscribeToVenueNotifications(client);
				subscribeToVenueOrders(client);
				subscribeToVenueRefetchOperationalLocationsCommand(client);
				subscribeToVenueRefetchOperationalOrdersCommand(client);
			}
		}

		start();

		return () => {
			if (client) {
				client.dispose();
				client.terminate();
			}
		};
	}, [venueId]);
};

const useRefetchOperationalData = () => {
	const { data: venue } = useGetCurrentVenue();
	const { refetch: refetchOrders } = useGetOperationalOrders(false);
	const { refetch: refetchLocations } = useGetOperationalLocations(false);
	const { refetch: refetchGeolocations } = useGetOperationalGeolocations(false);
	const { refetch: refetchNotifications } = useGetOperationalNotifications(false);

	useInterval(
		() => {
			refetchOrders();
			refetchLocations();
			refetchGeolocations();
			refetchNotifications();
		},
		!!venue && !!venue.operational_data_refetch_interval
			? bigMath.mul(venue.operational_data_refetch_interval, 60000)
			: null
	);
};
