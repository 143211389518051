import { useQuery } from '@tanstack/react-query';

import { hubGqlClient } from '~/libs/gql';
import { graphql } from '~/types/__generated/gql';
import { GetActiveMenuForPosQuery } from '~/types/__generated/gql/graphql';

const query = /* GraphQL */ `
	query GetActiveMenuForPos {
		getActiveMenuForPos {
			_id
			created_at
			updated_at
			name
			description
			banner_img
			enabled
			venue
			_categories {
				_id
				created_at
				updated_at
				title
				is_template
				clone_from
				ordering_index

				menu
				venue
				_pos_items {
					_id
					created_at
					updated_at
					title
					description
					banner_img
					is_qr_enabled
					is_pos_enabled
					type
					prep_time
					is_template
					clone_from
					ordering_index

					extra_quantity
					printer_tag
					original_price
					original_price_addons {
						vat {
							percentage
							amount
						}
						service_charge {
							percentage
							amount
						}
					}
					listed_price
					category
					menu
					venue
					recipe {
						raw_material
						unit_of_measurement
						consumed_unit_of_measurement
						consumed_quantity
					}
					options {
						ordering_index
						_option {
							_id
							created_at
							updated_at
							title
							is_template
							clone_from

							original_price
							original_price_addons {
								vat {
									percentage
									amount
								}
								service_charge {
									percentage
									amount
								}
							}
							listed_price
							menu
							venue
							recipe {
								raw_material
								unit_of_measurement
								consumed_unit_of_measurement
								consumed_quantity
							}
						}
					}
					option_groups {
						is_required
						minimum_select
						maximum_select
						ordering_index
						_option_group {
							_id
							title
							options
							clone_from
							_options {
								_id
								created_at
								updated_at
								title
								is_template
								clone_from

								original_price
								original_price_addons {
									vat {
										percentage
										amount
									}
									service_charge {
										percentage
										amount
									}
								}
								listed_price
								menu
								venue
								recipe {
									raw_material
									unit_of_measurement
									consumed_unit_of_measurement
									consumed_quantity
								}
							}
						}
					}
				}
			}
		}
	}
`;

export type GetActiveMenuForPosResults = GetActiveMenuForPosQuery['getActiveMenuForPos'];

export const getActiveMenuForPosQueryKey = () => ['getActiveMenuForPos'];

export const useGetActiveMenuForPos = (enabled?: boolean) => {
	return useQuery({
		enabled,
		queryKey: getActiveMenuForPosQueryKey(),
		queryFn: () => hubGqlClient.request(graphql(query)).then((res) => res.getActiveMenuForPos),
	});
};

export const useGetActiveMenuForPosCache = () => {
	return useGetActiveMenuForPos(false);
};
