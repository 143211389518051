import { GEOMETRIC_SHAPE_TYPE, USER_PERMISSIONS } from '@served/configs';

import { LanguageOptions } from './types';

const TILL_PAYMENT_TYPE = {
	PAY_LATER: 'pay_later',
	PAY_NOW: 'pay_now',
	PAID: 'paid',
};
const TILL_MODE = {
	TAKE_ORDER_PAYMENT: 'take-order-payment',
	ADD_TO_LOCATION: 'add-to-location',
	ADD_TO_ORDER: 'add-to-order',
	CLOSE_TAB: 'close-tab',
	EDIT_ORDER: 'edit-order',
};

const LOCATION_TYPES = [
	{ value: 'table', text: 'Table service' },
	{ value: 'pick_up_point', text: 'Pick up only' },
];

const DEFAULT_LOCATION_SHAPE = {
	SM_SQUARE: {
		center: [11.246234440473117, 104.69033001107161],
		size: 8000,
		type: GEOMETRIC_SHAPE_TYPE.SQUARE,
	},
	MD_SQUARE: {
		center: [11.247580620061564, 104.8991153140063],
		size: 15000,
		type: GEOMETRIC_SHAPE_TYPE.SQUARE,
	},
	LG_SQUARE: {
		center: [11.244888254595402, 105.22190838104343],
		size: 22000,
		type: GEOMETRIC_SHAPE_TYPE.SQUARE,
	},
	SM_CIRCLE: {
		center: [11.530137879548253, 104.68758283603299],
		radius: 5000,
		type: GEOMETRIC_SHAPE_TYPE.CIRCLE,
	},
	MD_CIRCLE: { center: [11.562108, 104.888535], radius: 8000, type: GEOMETRIC_SHAPE_TYPE.CIRCLE },
	LG_CIRCLE: {
		center: [11.540896392167227, 105.20542533081176],
		radius: 12000,
		type: GEOMETRIC_SHAPE_TYPE.CIRCLE,
	},
	SM_HORIZONTAL_RECTANGLE: {
		center: [11.89703678980424, 104.64225444789585],
		height: 0.035,
		width: 0.07,
		type: GEOMETRIC_SHAPE_TYPE.RECTANGLE,
	},
	MD_HORIZONTAL_RECTANGLE: {
		center: [11.89300759354596, 105.64772051202864],
		height: 0.07,
		width: 0.14,
		type: GEOMETRIC_SHAPE_TYPE.RECTANGLE,
	},
	LG_HORIZONTAL_RECTANGLE: {
		center: [11.867773863210743, 105.12599060308976],
		height: 0.105,
		width: 0.21,
		type: GEOMETRIC_SHAPE_TYPE.RECTANGLE,
	},
	SM_VERTICAL_RECTANGLE: {
		center: [11.89703678980424, 104.64225444789585],
		height: 0.07,
		width: 0.035,
		type: GEOMETRIC_SHAPE_TYPE.RECTANGLE,
	},
	MD_VERTICAL_RECTANGLE: {
		center: [11.89300759354596, 105.64772051202864],
		height: 0.14,
		width: 0.07,
		type: GEOMETRIC_SHAPE_TYPE.RECTANGLE,
	},
	LG_VERTICAL_RECTANGLE: {
		center: [11.867773863210743, 105.12599060308976],
		height: 0.21,
		width: 0.105,
		type: GEOMETRIC_SHAPE_TYPE.RECTANGLE,
	},
};

const DEFAULT_FLOORPLAN_SHAPE = {
	HORIZONTAL_WALL: {
		center: [11.89703678980424, 104.64225444789585],
		height: 0.009,
		width: 0.35,
		type: GEOMETRIC_SHAPE_TYPE.RECTANGLE,
	},
	VERTICAL_WALL: {
		center: [11.89300759354596, 105.64772051202864],
		height: 0.35,
		width: 0.009,
		type: GEOMETRIC_SHAPE_TYPE.RECTANGLE,
	},
	POOL_TABLE: {
		center: [11.867773863210743, 105.12599060308976],
		height: 0.1,
		width: 0.2,
		type: GEOMETRIC_SHAPE_TYPE.RECTANGLE,
	},
	SWIMMING_POOL: {
		center: [11.867773863210743, 105.12599060308976],
		height: 0.2,
		width: 0.4,
		type: GEOMETRIC_SHAPE_TYPE.RECTANGLE,
	},
	HORIZONTAL_BAR: {
		center: [11.867773863210743, 105.12599060308976],
		height: 0.045,
		width: 0.4,
		type: GEOMETRIC_SHAPE_TYPE.RECTANGLE,
	},
	VERTICAL_BAR: {
		center: [11.867773863210743, 105.12599060308976],
		height: 0.4,
		width: 0.045,
		type: GEOMETRIC_SHAPE_TYPE.RECTANGLE,
	},
};

enum PREFERRED_LOCATION_VIEW {
	LIST = 'list',
	FLOORPLAN = 'floorplan',
}

const KITCHEN_MESSAGE_RESERVED_CHARACTER = ';';

const LOWEST_DENOMINATOR = 5;

const DEFAULT_GROUP_NAME = 'All';

const LANGUAGE_OPTIONS: LanguageOptions = {
	en: {
		icon: '/images/flags/flag-uk.svg',
		label: 'English',
	},
	zh: {
		icon: '/images/flags/hk.svg.webp',
		label: 'Traditional Chinese',
	},
};

enum CALENDAR_VIEW {
	MONTH = 'dayGridMonth',
	WEEK = 'timeGridWeek',
	DAY = 'timeGridDay',
	AGENDA = 'listWeek',
}

const USER_PERMISSIONS_MAPPED = {
	ORDER_MANAGEMENT_FEATURE: {
		VIEW_ORDER: USER_PERMISSIONS.VIEW_ORDER,
		CREATE_ORDER: USER_PERMISSIONS.CREATE_ORDER,
		EDIT_ORDER_BEFORE_BILL_PRINTED: USER_PERMISSIONS.EDIT_ORDER_BEFORE_BILL_PRINTED,
		EDIT_ORDER_AFTER_BILL_PRINTED: USER_PERMISSIONS.EDIT_ORDER_AFTER_BILL_PRINTED,
		EDIT_ORDER_BEFORE_PAYMENT: USER_PERMISSIONS.EDIT_ORDER_BEFORE_PAYMENT,
		EDIT_ORDER_AFTER_PAYMENT: USER_PERMISSIONS.EDIT_ORDER_AFTER_PAYMENT,
		CANCEL_ORDER_BEFORE_BILL_PRINTED: USER_PERMISSIONS.CANCEL_ORDER_BEFORE_BILL_PRINTED,
		CANCEL_ORDER_AFTER_BILL_PRINTED: USER_PERMISSIONS.CANCEL_ORDER_AFTER_BILL_PRINTED,
		CANCEL_ORDER_BEFORE_PAYMENT: USER_PERMISSIONS.CANCEL_ORDER_BEFORE_PAYMENT,
		CANCEL_ORDER_AFTER_PAYMENT: USER_PERMISSIONS.CANCEL_ORDER_AFTER_PAYMENT,
	},
	RECEIPT_MANAGEMENT: {
		CANCEL_RECEIPT: USER_PERMISSIONS.CANCEL_RECEIPT,
	},
	USER_SETTINGS: {
		AUTO_LOGOUT: USER_PERMISSIONS.AUTO_LOGOUT,
	},
};

export const LOCATION_TYPE_TITLES = {
	table: 'Table service',
	pick_up_point: 'Pick up only',
} as const;

export {
	CALENDAR_VIEW,
	LOWEST_DENOMINATOR,
	DEFAULT_FLOORPLAN_SHAPE,
	DEFAULT_LOCATION_SHAPE,
	LOCATION_TYPES,
	PREFERRED_LOCATION_VIEW,
	TILL_MODE,
	TILL_PAYMENT_TYPE,
	KITCHEN_MESSAGE_RESERVED_CHARACTER,
	DEFAULT_GROUP_NAME,
	LANGUAGE_OPTIONS,
	USER_PERMISSIONS_MAPPED,
};
